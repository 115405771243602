import React from 'react';
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import '../scss/style.scss';

interface LayoutProps {
  bodyClass?: string;
}

const Layout: React.FC<LayoutProps> = ({bodyClass, children}) => (
  <>
    <SEO />
    <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper">
        <Header />
        {children}
      </div>
      <Footer />
    </div>
  </>
);

export default Layout;
